require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/0349c04b29756e2e5652d8181c07a42c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0349c04b29756e2e5652d8181c07a42c'] = async function fn_0349c04b29756e2e5652d8181c07a42c(x) {
return (await (async function(text) {
	function spaceNumbers(n){
	  const str = n?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "";
	  return str;
	}

	return spaceNumbers(text);
})(x))
}

})
define('./functions/a0131659b9850c9d4c1d63cda02f09c4/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a0131659b9850c9d4c1d63cda02f09c4'] = async function fn_a0131659b9850c9d4c1d63cda02f09c4(price_to, year_from, maker, model, fuel) {
var list;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  list = [];
  addItemToList(list, (['Active_until > \'',((new Date()).valueOf()),'\''].join('')));
  console.log('maker');
  console.log(maker);
  console.log('model');
  console.log(model);
  console.log('fuel');
  console.log(fuel);
  console.log('price to');
  console.log(price_to);
  console.log('year_from');
  console.log(year_from);
  if (0 != maker.length) {
    addItemToList(list, (['and Maker = \'',maker,'\''].join('')));
  }
  if (0 != model.length) {
    addItemToList(list, (['and Model = \'',model,'\''].join('')));
  }
  if (0 != fuel.length) {
    addItemToList(list, (['and Fuel_type = \'',fuel,'\''].join('')));
  }
  if (0 != price_to) {
    addItemToList(list, (String('and Price <  ') + String(price_to)));
  }
  if (0 != year_from) {
    addItemToList(list, (String('and Production_year > ') + String(year_from)));
  }
  console.log(list.join(' '));

return (await Backendless.Data.of('Offers_v2').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((list.join(' ')))))
}

})
define('./functions/a116bed81c0a95cc3f397a59cda3974d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a116bed81c0a95cc3f397a59cda3974d'] = async function fn_a116bed81c0a95cc3f397a59cda3974d(number) {
return (await (async function(num) {
	(typeof num == 'number') ? true : false;
	
	 

})(number))
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/Home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['typeCategory'] = 'car';
  ___arguments.context.pageData['results'] = (await Backendless.Data.of('Offers_v2').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((String('Status = \'Opublikowane\' and Active_until > ') + String((new Date()).valueOf())))));
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('8677a1485f84ad322bbf923dac11daf8', (await Backendless.Data.of('Maker').find(Backendless.DataQueryBuilder.create().setPageSize(100))));
  if (400 > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientWidth', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b38d71a43614d79d7d129581a5776a55'))))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ad1f1730fe77dbed71fd4aeab3bb8f7', true);
    ___arguments.context.pageData['mob_menu_visible'] = true;
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0ad1f1730fe77dbed71fd4aeab3bb8f7')), 'mobile');
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('0ad1f1730fe77dbed71fd4aeab3bb8f7')), 'web');
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Home/components/65ebb04ab177c6b332982a0ec482b78d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('oferty', ({ 'maker': ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('8677a1485f84ad322bbf923dac11daf8')),'model': ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('2f4c71ea752f703955d60cad73f67796')),'fuel': ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a042cc385fb8dd1aa6ebf651e62559af')),'year_from': (typeof (getObjectProperty(___arguments.context.pageData, 'year_from')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'year_from')) : 0,'price_to': (typeof (getObjectProperty(___arguments.context.pageData, 'price_to')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'price_to')) : 0 }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/7881e6c183536ea40385548cdc02191a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('oferty', ({ 'maker': ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('8677a1485f84ad322bbf923dac11daf8')),'model': ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('2f4c71ea752f703955d60cad73f67796')),'fuel': ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a042cc385fb8dd1aa6ebf651e62559af')),'year_from': (typeof (getObjectProperty(___arguments.context.pageData, 'year_from')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'year_from')) : 0,'price_to': (typeof (getObjectProperty(___arguments.context.pageData, 'price_to')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'price_to')) : 0 }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ('car' == (getObjectProperty(___arguments.context.pageData, 'typeCategory')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Home/components/26d4a273f9f57f8ab274b0ffdb5681d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['typeCategory'] = 'motorcycle';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var status2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'typeCategory')) == 'motorcycle') {
    status2 = ['nav-item', 'active'];
  } else {
    status2 = ['nav-item'];
  }

  return status2

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home/components/158ecf95352d98b355d6c049a05c2a6b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['typeCategory'] = 'car';

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var status2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'typeCategory')) {
    if ((getObjectProperty(___arguments.context.pageData, 'typeCategory')) == 'car') {
      status2 = ['nav-item', 'active'];
    } else {
      status2 = ['nav-item'];
    }
  } else {
    status2 = ['nav-item', 'active'];
  }

  return status2

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home/components/6b9233d2cda571df5d2178b15663cfc2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var status2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'typeCategory')) == 'truck') {
    status2 = ['nav-item', 'active'];
  } else {
    status2 = ['nav-item'];
  }

  return status2

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['typeCategory'] = 'truck';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/2d042d266ec1c5e3333b2fff9ff257b6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var status2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'typeCategory')) == 'heavyTruck') {
    status2 = ['nav-item', 'active'];
  } else {
    status2 = ['nav-item'];
  }

  return status2

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['typeCategory'] = 'heavyTruck';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/a811f40d47e23d286731af53c5beffd3/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var status2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'typeCategory')) == 'farm') {
    status2 = ['nav-item', 'active'];
  } else {
    status2 = ['nav-item'];
  }

  return status2

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['typeCategory'] = 'farm';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/7775209d032b45212fcf5fbae038fb42/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ('car' == (getObjectProperty(___arguments.context.pageData, 'typeCategory')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Home/components/a69be8bff7da807e58cf66d4b38e9dee/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ('car' == (getObjectProperty(___arguments.context.pageData, 'typeCategory')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Home/components/2117b6bbaa5b6b62dbcde8f16ac1e8b9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ('car' != (getObjectProperty(___arguments.context.pageData, 'typeCategory')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Home/components/8677a1485f84ad322bbf923dac11daf8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['maker_id'] = ___arguments.value;
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('2f4c71ea752f703955d60cad73f67796', (getObjectProperty((await Backendless.Data.of('Maker').findById((getObjectProperty(___arguments.context.pageData, 'maker_id')), Backendless.DataQueryBuilder.create().setRelated('Model').setRelationsDepth(1))), 'Model')));
  ___arguments.context.pageData['results'] = (await BackendlessUI.Functions.Custom['fn_a0131659b9850c9d4c1d63cda02f09c4'](((typeof (getObjectProperty(___arguments.context.pageData, 'price_to')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'price_to')) : 0), ((typeof (getObjectProperty(___arguments.context.pageData, 'year_from')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'year_from')) : 0), ___arguments.value, ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('2f4c71ea752f703955d60cad73f67796')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a042cc385fb8dd1aa6ebf651e62559af'))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home/components/2f4c71ea752f703955d60cad73f67796/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['results'] = (await BackendlessUI.Functions.Custom['fn_a0131659b9850c9d4c1d63cda02f09c4'](((typeof (getObjectProperty(___arguments.context.pageData, 'price_to')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'price_to')) : 0), ((typeof (getObjectProperty(___arguments.context.pageData, 'year_from')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'year_from')) : 0), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('8677a1485f84ad322bbf923dac11daf8')), ___arguments.value, ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a042cc385fb8dd1aa6ebf651e62559af'))));

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'maker_id')) === 'string'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/Home/components/a042cc385fb8dd1aa6ebf651e62559af/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['results'] = (await BackendlessUI.Functions.Custom['fn_a0131659b9850c9d4c1d63cda02f09c4'](((typeof (getObjectProperty(___arguments.context.pageData, 'price_to')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'price_to')) : 0), ((typeof (getObjectProperty(___arguments.context.pageData, 'year_from')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'year_from')) : 0), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('8677a1485f84ad322bbf923dac11daf8')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('2f4c71ea752f703955d60cad73f67796')), ___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home/components/989997035dce3f2d5bd4eff8cf274eb4/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['results'] = (await BackendlessUI.Functions.Custom['fn_a0131659b9850c9d4c1d63cda02f09c4'](((typeof (getObjectProperty(___arguments.context.pageData, 'price_to')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'price_to')) : 0), ((typeof (getObjectProperty(___arguments.context.pageData, 'year_from')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'year_from')) : 0), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('8677a1485f84ad322bbf923dac11daf8')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('2f4c71ea752f703955d60cad73f67796')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a042cc385fb8dd1aa6ebf651e62559af'))));

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/Home/components/e68333a50496f423375a808e68e8a5f1/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['results'] = (await BackendlessUI.Functions.Custom['fn_a0131659b9850c9d4c1d63cda02f09c4'](((typeof (getObjectProperty(___arguments.context.pageData, 'price_to')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'price_to')) : 0), ((typeof (getObjectProperty(___arguments.context.pageData, 'year_from')) === 'string') ? (getObjectProperty(___arguments.context.pageData, 'year_from')) : 0), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('8677a1485f84ad322bbf923dac11daf8')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('2f4c71ea752f703955d60cad73f67796')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('a042cc385fb8dd1aa6ebf651e62559af'))));

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/Home/components/7c9ca561529856ad0562e80a9be36c6d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://elomoto.store/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/4fdc7ceac076f3f7a5d538cb6da4db5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://energomix.com/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home/components/6543d1cb44ae21c14f2bb19512913778/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'investments_link'));
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.elomoto.eco/oferta/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_page/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3c18eb622048773861e3d4f3f1d7fb72', []);

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (!((await Backendless.UserService.getUserRoles()).includes('SystemAdmin'))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/admin_page/components/23e124d3d6dc718ecaf5e4cdd858c27b/bundle.js', [], () => ({
  /* content */
  /* handler:onRecordSelect */
  async ['onRecordSelect'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['maker_id'] = (getObjectProperty(___arguments.selectedRecord, 'objectId'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3c18eb622048773861e3d4f3f1d7fb72', (getObjectProperty((await Backendless.Data.of('Maker').findById((getObjectProperty(___arguments.context.pageData, 'maker_id')), Backendless.DataQueryBuilder.create().setRelated('Model').setRelationsDepth(1))), 'Model')));

  },
  /* handler:onRecordSelect */
  /* content */
}))

define('./pages/admin_page/components/63857aa2eef13a051a9e2eb121ff8817/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_page/components/f3b61bee295d1d2262fc2a52db2ac81f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['selected_model'] = ___arguments.context.getComponentDataStoreByUid('0921c70196bcced8acda64528349b483');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('d30aa3e5cbb2ca06ff1bc5493b0295a8');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_page/components/d30aa3e5cbb2ca06ff1bc5493b0295a8/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('Maker').deleteRelation((getObjectProperty(___arguments.context.pageData, 'maker_id')), 'Model', [(getObjectProperty(___arguments.context.pageData, 'selected_model'))]);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3c18eb622048773861e3d4f3f1d7fb72', []);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3c18eb622048773861e3d4f3f1d7fb72', (getObjectProperty((await Backendless.Data.of('Maker').findById((getObjectProperty(___arguments.context.pageData, 'maker_id')), Backendless.DataQueryBuilder.create().setRelated('Model').setRelationsDepth(1))), 'Model')));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d30aa3e5cbb2ca06ff1bc5493b0295a8');

  },
  /* handler:onSubmit */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('d30aa3e5cbb2ca06ff1bc5493b0295a8');

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/admin_page/components/d7b5c0385e816f6358c5cbbbb46eadba/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('Maker').addRelation((getObjectProperty(___arguments.context.pageData, 'maker_id')), 'Model', [(await Backendless.Data.of('Model').save(({ 'name': (getObjectProperty(___arguments.context.pageData, 'new_model_name')) }), true))]);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3c18eb622048773861e3d4f3f1d7fb72', []);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3c18eb622048773861e3d4f3f1d7fb72', (getObjectProperty((await Backendless.Data.of('Maker').findById((getObjectProperty(___arguments.context.pageData, 'maker_id')), Backendless.DataQueryBuilder.create().setRelated('Model').setRelationsDepth(1))), 'Model')));
  ___arguments.context.pageData['new_model_name'] = undefined;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_page/components/2701d5b46d921b8ff28fc2192e8e05b6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await Backendless.UserService.getUserRoles()).includes('SystemAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_page/components/152f09493f0722b5a635586df50a704e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await Backendless.UserService.getUserRoles()).includes('SystemAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_page/components/3b790fc13d38a53a6843bf0989915337/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await Backendless.UserService.getUserRoles()).includes('SystemAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/buy-credits/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var respond;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['Credits'] = (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'Credits'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('495a622a5c2b5ba911df5bfdfbba99d7', (await Backendless.Data.of('Credits_offer').find(Backendless.DataQueryBuilder.create().setSortBy('Amount ASC').setPageSize(10))));

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    console.log('logged out');
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('../system/index.html', false, 'page=login&redirectToUrl=../main-2/index.html?page=buy_credits&sendUserTokenAndId=true');
  } else {
    console.log('logged in');
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/buy-credits/components/e3f200a105b64ef944149ef9638e66ad/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}

function subsequenceFromEndLast(sequence, at1) {
  var start = sequence.length - 1 - at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return ([subsequenceFirstFromEnd(String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a48e8c978fac3b06a8cc80242c1ad29d'), 'Price')), 2),'.',subsequenceFromEndLast(String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a48e8c978fac3b06a8cc80242c1ad29d'), 'Price')), 1)].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/buy-credits/components/123f83349ff2784985c1805788929184/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if ('private' == ___arguments.checkedValue) {
    ___arguments.context.getComponentDataStoreByUid('db1302ac32249ee91673b1b8e9f6419c')['private_buyer'] = true;
    ___arguments.context.getComponentDataStoreByUid('db1302ac32249ee91673b1b8e9f6419c')['company_buyer'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('db1302ac32249ee91673b1b8e9f6419c')['company_buyer'] = true;
    ___arguments.context.getComponentDataStoreByUid('db1302ac32249ee91673b1b8e9f6419c')['private_buyer'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/buy-credits/components/a218fddd7fc81e08786fcc8dc41acd8d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['selected'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selected'))

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/buy-credits/components/db1302ac32249ee91673b1b8e9f6419c/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var return2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);
  console.log(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'));
  return2 = (await Backendless.Request.post(`${Backendless.appPath}/services/PayU/authorisation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'ipAddress': (getObjectProperty((await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})('https://api.db-ip.com/v2/free/self')).setEncoding('utf8').send()), 'ipAddress')),'continueUrl': (getObjectProperty(___arguments.context.pageData, 'return_url')),'productId': (getObjectProperty(___arguments.context.pageData, 'selected')),'userId': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'billingData': ({ [`buyer`]: (getObjectProperty(___arguments.context.dataModel, 'buyer')),[`name`]: (getObjectProperty(___arguments.context.dataModel, 'name')),[`secondName`]: (getObjectProperty(___arguments.context.dataModel, 'second_name')),[`companyName`]: (getObjectProperty(___arguments.context.dataModel, 'company_name')),[`city`]: (getObjectProperty(___arguments.context.dataModel, 'city')),[`address`]: (getObjectProperty(___arguments.context.dataModel, 'address')),[`zipCode`]: (getObjectProperty(___arguments.context.dataModel, 'zip_code')),[`email`]: (getObjectProperty(___arguments.context.dataModel, 'email')),[`phone`]: (getObjectProperty(___arguments.context.dataModel, 'phone')),[`nip`]: (getObjectProperty(___arguments.context.dataModel, 'nip_number')) }) }));
  console.log(return2);
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(return2, 'error.body.redirectUri')), false, undefined);

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
      (function () { window.history.back(); })();

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/buy-credits/components/52b7510bd4853258c6d4fa5f8cd8c4f1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('db1302ac32249ee91673b1b8e9f6419c'), 'buyer')) === 'string') && (typeof (getObjectProperty(___arguments.context.pageData, 'selected')) === 'string') && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('db1302ac32249ee91673b1b8e9f6419c'), 'privacy_bool')) && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('db1302ac32249ee91673b1b8e9f6419c'), 'terms_bool'))))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/buy-credits/components/1fbc2922cc0ea35f8233018b3d11333f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.elomoto.market/?page=privacy', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/buy-credits/components/b3739dd566177c33cfdfe2db6c4b66fd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.elomoto.market/?page=terms', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/car_finance/components/37bf9cbd12bcfbf9e2afe5fa834e3075/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f8bbfd339f316eef237ed8a99b3b260b', (await Backendless.Data.of('Charging_stations').find(Backendless.DataQueryBuilder.create().setPageSize(10))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/car_finance/components/033156bb973e30486caa271799341bf8/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Messaging.sendEmailFromTemplate('carFinance', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(((await Backendless.Data.of('Config').find(Backendless.DataQueryBuilder.create().setWhereClause('key = \'carFinance_email\'').setProperties('value').setPageSize(1)))[0]), 'value'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ [`email`]: (getObjectProperty(___arguments.context.dataModel, 'email')),[`name`]: (getObjectProperty(___arguments.context.dataModel, 'name')),[`phone`]: (JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'phone')))),[`pageLink`]: (getObjectProperty(___arguments.context.pageData, 'offer_link')) }), undefined);
  (function () { window.history.back(); })();

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/contact-us/components/37bf9cbd12bcfbf9e2afe5fa834e3075/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f8bbfd339f316eef237ed8a99b3b260b', (await Backendless.Data.of('Charging_stations').find(Backendless.DataQueryBuilder.create().setPageSize(10))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/contact-us/components/033156bb973e30486caa271799341bf8/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Messaging.sendEmailFromTemplate('Chargers', Backendless.EmailEnvelope.create({ addresses: [(getObjectProperty(((await Backendless.Data.of('Config').find(Backendless.DataQueryBuilder.create().setWhereClause('key = \'charging_station_email\'').setProperties('value').setPageSize(1)))[0]), 'value'))], ccAddresses: undefined, bccAddresses: undefined, query: '', uniqueEmails: false }), ({ [`phone`]: (JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'phone')))),[`name`]: (getObjectProperty(___arguments.context.dataModel, 'name')),[`message`]: (getObjectProperty(___arguments.context.dataModel, 'message')),[`email`]: (getObjectProperty(___arguments.context.dataModel, 'email')) }), undefined);

  },
  /* handler:onSubmit */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      ___arguments.context.dataModel['privacy_declined'] = true;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/contact-us/components/c8abb477630bc4dd0de61b263b7bd12f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('033156bb973e30486caa271799341bf8')['privacy_declined'] = (!___arguments.value);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/credits/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var respond;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.pageData);
  console.log('Page data above');
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('2849edf4b34abe9af12edbc1b12a9c35', (await Backendless.Data.of('Prices').find(Backendless.DataQueryBuilder.create().setPageSize(10))));
  ___arguments.context.pageData['Credits'] = (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'Credits'));
  console.log(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId'));
  if (typeof (getObjectProperty(___arguments.context.pageData, 'orderId')) === 'string') {
    console.log(getObjectProperty((await Backendless.Data.of('Orders').findById((getObjectProperty(___arguments.context.pageData, 'orderId')), )), 'billingData'));
    respond = (await Backendless.Request.post(`${Backendless.appPath}/services/PayU/refresh`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'userId': (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'offerId': (getObjectProperty(___arguments.context.pageData, 'orderId')) }));
    ___arguments.context.pageData['Credits'] = (getObjectProperty(respond, 'sum_of_credits'));
    console.log(respond);
    if ('CANCELED' == (getObjectProperty(respond, 'order_status'))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eeaa71a3030bc137ce6a66f39aa342b9', true);
      ___arguments.context.pageData['transaction_status'] = 'Transakcja nie powiodła się. Spróbuj ponownie lub skontaktuj się z nami.';
    }
    if ('NEW' == (getObjectProperty(respond, 'order_status')) || 'PENDING' == (getObjectProperty(respond, 'order_status'))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eeaa71a3030bc137ce6a66f39aa342b9', true);
      ___arguments.context.pageData['transaction_status'] = 'Oczekujemy na potwierdzenie transakcji. Odśwież stronę. Jeżeli czekasz dłuższą chwilę, skontaktuj się z nami.';
    }
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    console.log('logged out');
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('../system/index.html', false, 'page=login&redirectToUrl=../main-2/index.html?page=buy_credits&sendUserTokenAndId=true');
  } else {
    console.log('logged in');
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/credits/components/eab7028c19d745deab36fb481bbcfbc6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selected'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a0ca06974bdc083e6767ef426ea27fa8'), 'offer_type'));
  ___arguments.context.pageData['selected_level'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a0ca06974bdc083e6767ef426ea27fa8'), 'Featured_level'));
  ___arguments.context.pageData['selected_price'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a0ca06974bdc083e6767ef426ea27fa8'), 'price_in_credits'));

  },
  /* handler:onChange */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selected'))

  },
  /* handler:onCheckedValueAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a0ca06974bdc083e6767ef426ea27fa8'), 'offer_type')),' (',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a0ca06974bdc083e6767ef426ea27fa8'), 'price_in_credits')),' kredytów)'].join(''))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'Credits')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a0ca06974bdc083e6767ef426ea27fa8'), 'price_in_credits')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/credits/components/fb94b307a037377283cc4c711bce0d4d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Request.post(`${Backendless.appPath}/services/manage_offers/publish`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'offer_id': (getObjectProperty(___arguments.context.pageData, 'idOffer')),'featured': (getObjectProperty(___arguments.context.pageData, 'selected_level')),'price': (getObjectProperty(___arguments.context.pageData, 'selected_price')) });
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('my_account', undefined);

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.pageData, 'selected')) === 'undefined')

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/credits/components/f42d666a5d2a3f4bdea7f781ba8f0abe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var number, text;


  number = ((function () { return window.location.href })()).indexOf('&orderId') + 1;
  if (0 < number) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('buy-credits', ({ [`return_url`]: ((function () { return window.location.href })()).slice(0, number) }));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('buy-credits', ({ [`return_url`]: ((function () { return window.location.href })()) }));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/my_account/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('0bea0a1d9ed8ae9730feb985be1d8fe4', (await Backendless.Data.of('Offers_v2').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId = \'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\' and Active_until > ',((new Date()).valueOf())].join(''))).setSortBy('featured DESC').setPageSize(100))));
  ___arguments.context.pageData['marketing'] = (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'marketing_consent'));

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if (!(await Backendless.UserService.isValidLogin())) {
    console.log('logged out');
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('../system/index.html', false, 'page=login&redirectToUrl=../main-2/index.html?page=my_account&sendUserTokenAndId=true');
  } else {
    console.log('logged in');
    console.log(await Backendless.UserService.getCurrentUser(false));
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/my_account/components/7afbfe3dfd9c050f4e58b23e5ae1656c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('offer_details', ({ 'idOffer': (getObjectProperty(___arguments.context.dataModel, 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/my_account/components/eb11e4f65eca3f0c80c1ab2b144e8586/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ('Aktywne' == ___arguments.value) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('0bea0a1d9ed8ae9730feb985be1d8fe4', (await Backendless.Data.of('Offers_v2').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId = \'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\' and Active_until > ',((new Date()).valueOf())].join(''))).setSortBy('featured DESC').setPageSize(100))));
  } else if ('Zamknięte' == ___arguments.value) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('0bea0a1d9ed8ae9730feb985be1d8fe4', (await Backendless.Data.of('Offers_v2').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId = \'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\' and Active_until < ',((new Date()).valueOf())].join(''))).setSortBy('featured DESC').setPageSize(100))));
  } else if ('Robocze' == ___arguments.value) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('0bea0a1d9ed8ae9730feb985be1d8fe4', (await Backendless.Data.of('Offers_v2').find(Backendless.DataQueryBuilder.create().setWhereClause((['ownerId = \'',(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')),'\' and Active_until is null'].join(''))).setSortBy('featured DESC').setPageSize(100))));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/my_account/components/81abdd13702554968d8afbc33d8b985b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.elomoto.market/?page=marketing', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/my_account/components/cb970821c0ff503527d9f595d0d9672e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var user;


  user = (await Backendless.UserService.getCurrentUser(false));
  user['marketing_consent'] = ___arguments.value;
  await Backendless.UserService.update( new Backendless.User(user) );

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/oferty/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var results;


  ___arguments.context.pageData['page_number'] = 1;
  ___arguments.context.pageData['where'] = [];
  results = (await Backendless.Data.of('Offers_v2').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((String('Status = \'Opublikowane\' and Active_until > ') + String((new Date()).valueOf())))));
  ___arguments.context.pageData['results'] = results;
  ___arguments.context.pageData['results_number'] = (Math.ceil(results / 100));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('0bea0a1d9ed8ae9730feb985be1d8fe4', (await Backendless.Data.of('Offers_v2').find(Backendless.DataQueryBuilder.create().setWhereClause((String('Status = \'Opublikowane\' and Active_until > ') + String((new Date()).valueOf()))).setSortBy('featured desc').setPageSize(100))));
  ___arguments.context.pageData['count'] = (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('0bea0a1d9ed8ae9730feb985be1d8fe4')).length);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/oferty/components/7afbfe3dfd9c050f4e58b23e5ae1656c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('offer_details', ({ 'idOffer': (getObjectProperty(___arguments.context.dataModel, 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/oferty/components/53c3e3b6e0ea396b2619e16a8c64a5aa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['maker_id'] = ___arguments.value;
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('6e61f13273899e81efa4bb8ade7302dd', (getObjectProperty((await Backendless.Data.of('Maker').findById((getObjectProperty(___arguments.context.pageData, 'maker_id')), Backendless.DataQueryBuilder.create().setRelated('Model').setRelationsDepth(1))), 'Model')));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/oferty/components/2509132e572150dd1d759fdbea3dbadc/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var where;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('53c3e3b6e0ea396b2619e16a8c64a5aa', (await Backendless.Data.of('Maker').find(Backendless.DataQueryBuilder.create().setPageSize(100))));
  console.log((getObjectProperty(___arguments.context.pageData, 'maker')).length);
  console.log((getObjectProperty(___arguments.context.pageData, 'model')).length);
  console.log((getObjectProperty(___arguments.context.pageData, 'fuel')).length);
  where = ['1 = 1'];
  if (3 < (getObjectProperty(___arguments.context.pageData, 'maker')).length) {
    addItemToList(where, (['and Maker = \'',(getObjectProperty(___arguments.context.pageData, 'maker')),'\''].join('')));
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('53c3e3b6e0ea396b2619e16a8c64a5aa', (getObjectProperty(___arguments.context.pageData, 'maker')));
    console.log(getObjectProperty((await Backendless.Data.of('Maker').findById((getObjectProperty(___arguments.context.pageData, 'maker')), Backendless.DataQueryBuilder.create().setRelated('Model').setRelationsDepth(1))), 'Model'));
    (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('6e61f13273899e81efa4bb8ade7302dd', (getObjectProperty((await Backendless.Data.of('Maker').findById((getObjectProperty(___arguments.context.pageData, 'maker')), Backendless.DataQueryBuilder.create().setRelated('Model').setRelationsDepth(1))), 'Model')));
  }
  if (3 < (getObjectProperty(___arguments.context.pageData, 'model')).length) {
    addItemToList(where, (['and Model = \'',(getObjectProperty(___arguments.context.pageData, 'model')),'\''].join('')));
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('6e61f13273899e81efa4bb8ade7302dd', (getObjectProperty(___arguments.context.pageData, 'model')));
  }
  if (3 < (getObjectProperty(___arguments.context.pageData, 'fuel')).length) {
    addItemToList(where, (['and Fuel_type = \'',(getObjectProperty(___arguments.context.pageData, 'fuel')),'\''].join('')));
    (function (componentUid, value) { ___arguments.context.getComponentByUid(componentUid).value = value })('0fca59073a526e374a4a389f82260f73', (getObjectProperty(___arguments.context.pageData, 'fuel')));
  }
  if (0 < (getObjectProperty(___arguments.context.pageData, 'year_from'))) {
    addItemToList(where, (String('and Production_year > ') + String(getObjectProperty(___arguments.context.pageData, 'year_from'))));
    ___arguments.context.dataModel['prod_year_from'] = (getObjectProperty(___arguments.context.pageData, 'year_from'));
  }
  if (0 < (getObjectProperty(___arguments.context.pageData, 'price_to'))) {
    addItemToList(where, (String('and Price <  ') + String(getObjectProperty(___arguments.context.pageData, 'price_to'))));
    ___arguments.context.dataModel['price_to'] = (getObjectProperty(___arguments.context.pageData, 'price_to'));
  }
  console.log('where:');
  console.log(where.join(' '));
  if (1 < where.length) {
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('2509132e572150dd1d759fdbea3dbadc');
  }

  },
  /* handler:onMounted */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var list;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  list = [String('Status = \'Opublikowane\' and Active_until > ') + String((new Date()).valueOf())];
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('fee5084675fffb1b5c82137543432a2c'))) {
    addItemToList(list, (['and Body_type = \'',((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('fee5084675fffb1b5c82137543432a2c')),'\''].join('')));
  }
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('53c3e3b6e0ea396b2619e16a8c64a5aa'))) {
    addItemToList(list, (['and Maker = \'',((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('53c3e3b6e0ea396b2619e16a8c64a5aa')),'\''].join('')));
  }
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('6e61f13273899e81efa4bb8ade7302dd'))) {
    addItemToList(list, (['and Model = \'',((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('6e61f13273899e81efa4bb8ade7302dd')),'\''].join('')));
  }
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('fee5084675fffb1b5c82137543432a2c'))) {
    addItemToList(list, (['and Body_type = \'',((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('fee5084675fffb1b5c82137543432a2c')),'\''].join('')));
  }
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('e171d66d6e9c39574b1b6d2f9cf9cff6'))) {
    addItemToList(list, (['and Drive = \'',((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('e171d66d6e9c39574b1b6d2f9cf9cff6')),'\''].join('')));
  }
  if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('0fca59073a526e374a4a389f82260f73'))) {
    addItemToList(list, (['and Fuel_type = \'',((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('0fca59073a526e374a4a389f82260f73')),'\''].join('')));
  }
  if (!(typeof (getObjectProperty(___arguments.context.dataModel, 'price_from')) === 'undefined')) {
    addItemToList(list, (String('and Price >  ') + String(getObjectProperty(___arguments.context.dataModel, 'price_from'))));
  }
  if (!(typeof (getObjectProperty(___arguments.context.dataModel, 'price_to')) === 'undefined')) {
    addItemToList(list, (String('and Price <  ') + String(getObjectProperty(___arguments.context.dataModel, 'price_to'))));
  }
  if (!(typeof (getObjectProperty(___arguments.context.dataModel, 'millage_to')) === 'undefined')) {
    addItemToList(list, (String('and Milage <  ') + String(getObjectProperty(___arguments.context.dataModel, 'millage_to'))));
  }
  if (!(typeof (getObjectProperty(___arguments.context.dataModel, 'prod_year_from')) === 'undefined')) {
    addItemToList(list, (String('and Production_year > ') + String(getObjectProperty(___arguments.context.dataModel, 'prod_year_from'))));
  }
  if (!(typeof (getObjectProperty(___arguments.context.dataModel, 'prod_year_to')) === 'undefined')) {
    addItemToList(list, (String('and Production_year < ') + String(getObjectProperty(___arguments.context.dataModel, 'prod_year_to'))));
  }
  console.log(list.join(' '));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('0bea0a1d9ed8ae9730feb985be1d8fe4', (await Backendless.Data.of('Offers_v2').find(Backendless.DataQueryBuilder.create().setWhereClause((list.join(' '))).setSortBy('featured desc').setPageSize(100).setOffset((((getObjectProperty(___arguments.context.pageData, 'page_number')) - 1) * 100)))));
  ___arguments.context.pageData['results'] = (await Backendless.Data.of('Offers_v2').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((list.join(' ')))));

  },
  /* handler:onSubmit */
  /* handler:onReset */
  async ['onReset'](___arguments) {
      (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('6e61f13273899e81efa4bb8ade7302dd', []);
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('53c3e3b6e0ea396b2619e16a8c64a5aa', (await Backendless.Data.of('Maker').find(Backendless.DataQueryBuilder.create().setPageSize(100))));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('0bea0a1d9ed8ae9730feb985be1d8fe4', (await Backendless.Data.of('Offers_v2').find(Backendless.DataQueryBuilder.create().setSortBy('featured desc').setPageSize(10))));
  ___arguments.context.pageData['results'] = (await Backendless.Data.of('Offers_v2').getObjectCount());

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/oferty/components/e9fda65c2b2cb2dc33527c4eb6b3c6de/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Odnaleziono ',(getObjectProperty(___arguments.context.pageData, 'results')),' pojazdów.'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/oferty/components/5bd14421d74ee7df3a46ad080f445e65/bundle.js', [], () => ({
  /* content */
  /* handler:onPageChange */
  ['onPageChange'](___arguments) {
      ___arguments.context.pageData['page_number'] = ___arguments['currentPage'];
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('2509132e572150dd1d759fdbea3dbadc');

  },
  /* handler:onPageChange */
  /* content */
}))

define('./pages/oferty/components/6e61f13273899e81efa4bb8ade7302dd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'maker_id')) === 'string'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/offer_details/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onPageQuery */
  async ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'idOffer'));
  if (getObjectProperty(___arguments.context.pageData, 'idOffer')) {
    console.log('not null');
    ___arguments.context.pageData['offer'] = (await Backendless.Data.of('Offers_v2').findById((getObjectProperty(___arguments.context.pageData, 'idOffer')), ));
    ___arguments.context.pageData['offer.Maker'] = (getObjectProperty((await Backendless.Data.of('Maker').findById((getObjectProperty(___arguments.context.pageData, 'offer.Maker')), Backendless.DataQueryBuilder.create().setProperties(['name']))), 'name'));
    ___arguments.context.pageData['offer.Model'] = (getObjectProperty((await Backendless.Data.of('Model').findById((getObjectProperty(___arguments.context.pageData, 'offer.Model')), Backendless.DataQueryBuilder.create().setProperties(['name']))), 'name'));
    ___arguments.context.pageData['phone_number_label'] = 'Wyświetl numer';
    if (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_audio')) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('777e3b0d269b9c6bd307ee1313318fbc', (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_audio')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('91de617874afaa13c438ce4825af7fc1', undefined);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('777e3b0d269b9c6bd307ee1313318fbc', undefined);
    }
    if (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_comfort')) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c08d418eba8f92daf5b3ab8ccd2f82b1', (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_comfort')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2255ab68060a915a96fd1e0c2745cc6f', undefined);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c08d418eba8f92daf5b3ab8ccd2f82b1', undefined);
    }
    if (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_electrics')) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('2bcf7b5e3bf3e59c925220034bbc384b', (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_electrics')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e4aa678d3a6924bc4fcba4848bfb2d84', undefined);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2bcf7b5e3bf3e59c925220034bbc384b', undefined);
    }
    if (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_support')) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('50a48b73db37ded81a6c90518a12884e', (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_support')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('692216e4f131c9a55afba04bd00ad2d9', undefined);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('50a48b73db37ded81a6c90518a12884e', undefined);
    }
    if (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_tuning')) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('5de1073bafadd8d2db23dfdb2c312be7', (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_tuning')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eb8f1cd24e013f1e9966d0211b3456e5', undefined);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5de1073bafadd8d2db23dfdb2c312be7', undefined);
    }
    if (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_safety')) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('f097bcb70d83bb316e37e2e2efbcd373', (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_safety')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a3869d04dae429edd6b36a89edc3dca2', undefined);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f097bcb70d83bb316e37e2e2efbcd373', undefined);
    }
    if (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_paperwork')) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('6d7e07aca9bc73dd81c2912a50096883', (getObjectProperty(___arguments.context.pageData, 'offer.Equipement_paperwork')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0b1a0ed4e25a8302689a88e97ab7ee9a', undefined);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6d7e07aca9bc73dd81c2912a50096883', undefined);
    }
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('470484ae633452981c18846f3807b4ed', ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == (getObjectProperty(___arguments.context.pageData, 'offer.ownerId')) && 'Robocze' == (getObjectProperty(___arguments.context.pageData, 'offer.Status'))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('778bdc7f319958c6713b8055a9a8a264', ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == (getObjectProperty(___arguments.context.pageData, 'offer.ownerId')) && 'Opublikowane' == (getObjectProperty(___arguments.context.pageData, 'offer.Status'))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('126d081fdb6263dc095d08f89ae20902', ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == (getObjectProperty(___arguments.context.pageData, 'offer.ownerId')) && ('Zakończone' == (getObjectProperty(___arguments.context.pageData, 'offer.Status')) || (new Date()) > (getObjectProperty(___arguments.context.pageData, 'offer.Active_until')) && 10 < (getObjectProperty(___arguments.context.pageData, 'offer.Active_until')))));
  } else {
    console.log('null :(');
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('oferty', undefined);
  }

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/offer_details/components/317903a3ac51b4365dd5502fe904dcb9/bundle.js', [], () => ({
  /* content */
  /* handler:imagesLogic */
  ['imagesLogic'](___arguments) {
    var images;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  images = (getObjectProperty(___arguments.context.pageData, 'offer.Photos'));

  return images

  },
  /* handler:imagesLogic */
  /* content */
}))

define('./pages/offer_details/components/55b67c6e8a353ba6d875ce8e5f9edbab/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return [(await BackendlessUI.Functions.Custom['fn_0349c04b29756e2e5652d8181c07a42c']((getObjectProperty(___arguments.context.pageData, 'offer.Price')))), ' zł']

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/offer_details/components/7c2234075f8adf1f2a878e8aadd39f98/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return [(await BackendlessUI.Functions.Custom['fn_0349c04b29756e2e5652d8181c07a42c']((getObjectProperty(___arguments.context.pageData, 'offer.Engine_capacity')))), ' cm3']

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/offer_details/components/27e5618bde49e36369ac5d2558bf01e1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return [(getObjectProperty(___arguments.context.pageData, 'offer.Horse_Power')), ' KM']

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/offer_details/components/e45d0232e13df9ff7fe8b4f755cfbc3c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return [(getObjectProperty(___arguments.context.pageData, 'offer.Milage')), ' km']

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/offer_details/components/4f835262239dad81754ac5550caa00db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['phone_number_label'] = (getObjectProperty(___arguments.context.pageData, 'offer.Phone_number'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/offer_details/components/6ed257ef3649118549a8123c9872839f/bundle.js', [], () => ({
  /* content */
  /* handler:onMapCenterAssignment */
  ['onMapCenterAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ({ 'lat': (getObjectProperty(___arguments.context.pageData, 'offer.Location_geo.lat')),'lng': (getObjectProperty(___arguments.context.pageData, 'offer.Location_geo.lng')) })

  },
  /* handler:onMapCenterAssignment */
  /* handler:onPointsAssignment */
  ['onPointsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (null == (getObjectProperty(___arguments.context.pageData, 'offer.Location_geo.lat')) ? [] : [({ 'lat': (getObjectProperty(___arguments.context.pageData, 'offer.Location_geo.lat')),'lng': (getObjectProperty(___arguments.context.pageData, 'offer.Location_geo.lng')) })])

  },
  /* handler:onPointsAssignment */
  /* content */
}))

define('./pages/offer_details/components/b72a1203f524797214de024eaaff0dc1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('register-offer', ({ 'idOffer': (getObjectProperty(___arguments.context.pageData, 'idOffer')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/offer_details/components/159577f45e6e86f7fab901abeee2a76c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('credits', ({ 'idOffer': (getObjectProperty(___arguments.context.pageData, 'idOffer')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/offer_details/components/aca33994b32cc49c82065b43c1c9d674/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('5b3948068fdee099108689c32148d2d7');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/offer_details/components/5b3948068fdee099108689c32148d2d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5b3948068fdee099108689c32148d2d7');

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Request.post(`${Backendless.appPath}/services/manage_offers/close`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'idOffer'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('778bdc7f319958c6713b8055a9a8a264', false);
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5b3948068fdee099108689c32148d2d7');

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/offer_details/components/c14120bdb80e1106f9e50dc40711a3b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('credits', ({ [`idOffer`]: (getObjectProperty(___arguments.context.pageData, 'idOffer')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/offer_details/components/9671461c523212aa6467a0b7fc5bb26a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('car_finance', ({ [`offer_link`]: ((function () { return window.location.href })()) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/register-offer/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	accordions = document.querySelectorAll(".accordion-item");
  	
  	accordions.forEach((accordion)=>{
  	  const header = accordion.querySelector(".accordion-header");
  	  const body = accordion.querySelector(".accordion-body")
  	  let isOpen = false;
  	  
  	  accordion.classList.add("is-closed");
  	  body.style.maxHeight = 0 + "px";
  	  console.log("acordeon logic");
  	  header.addEventListener("click", () => {
  	    if(isOpen){
  	      accordion.classList.remove("is-open");
  	      accordion.classList.add("is-closed");
  	      body.style.maxHeight = 0 + "px";
  	    } else {
  	      accordion.classList.add("is-open");
  	      accordion.classList.remove("is-closed");
  	      body.style.maxHeight = body.scrollHeight + "px";
  	    }
  	
  	    isOpen = !isOpen;
  	    
  	  });
  	  
  	})
  })();
  await (async function() {
  	const accordionsQuantity = document.querySelectorAll('.accordion-quantity')
  	
  	accordionsQuantity.forEach(acQ => {
  	    const checkboxs = acQ.querySelectorAll('input[type="checkbox"]');
  	    const accordionTitle = acQ.querySelector('.accordion-title');
  	    let activeCount = 0;
  	    
  	    let headQuantity = document.createElement("i");
  	    headQuantity.className = "quantity"; 
  	    
  	    accordionTitle.appendChild(headQuantity);
  	    
  	    function getCheckedCount() {
  	      acQ.querySelector('.quantity').innerHTML = `${activeCount}/${checkboxs.length}`
  	    }
  	
  	    checkboxs.forEach(checkbox => {
  	      getCheckedCount();
  	      
  	      checkbox.addEventListener("click", () => {
  	        checkbox.checked ? activeCount++ : activeCount--;
  	        getCheckedCount();
  	      });
  	    });
  	    
  	});
  })();
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fdd44adf911fd2b51ebeb823239afbe8', (getObjectProperty(___arguments.context.pageData, 'uploadImages')));
  ___arguments.context.pageData['models_list'] = [];
  ___arguments.context.pageData['offer_details'] = ({  });
  (function (componentUid, bounds, componentRestrictions, location, offset, origin, radius, sessionToken, types) { ___arguments.context.getComponentByUid(componentUid).placesPredictionsSettings = { bounds, componentRestrictions, location, offset, origin, radius, sessionToken } })('03ed7e82f0abdcfd8e6b437522c9651c', undefined, ({ 'country': 'pl' }), undefined, undefined, undefined, undefined, undefined, ['locality']);

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
      if ((await Backendless.UserService.getCurrentUser(false)) === null) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/register-offer/components/129cde52983945aec09fadd6b702952d/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
    var pictures_urls, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (getObjectProperty(___arguments.context.pageData, 'uploadImages')) {
    if (___arguments.files.length + (getObjectProperty(___arguments.context.pageData, 'uploadImages')).length > 30) {
      ___arguments.context.pageData['failMessageFiles'] = 'Za duża ilość zdjęć';
      throw (new Error(''))
    }
  } else {
    if (___arguments.files.length > 30) {
      ___arguments.context.pageData['failMessageFiles'] = 'Za duża ilość zdjęć';
      throw (new Error(''))
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'uploadImages')) {
    addItemToList((getObjectProperty(___arguments.context.pageData, 'uploadImages')), ___arguments.files);
  } else {
    ___arguments.context.pageData['uploadImages'] = ___arguments.files;
  }
  ___arguments.context.pageData['failMessageFiles'] = '';
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fdd44adf911fd2b51ebeb823239afbe8', (getObjectProperty(___arguments.context.pageData, 'uploadImages')));
  throw (new Error(''))

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadFail */
  async ['onUploadFail'](___arguments) {
      await new Promise(r => setTimeout(r, 2000 || 0));
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('129cde52983945aec09fadd6b702952d');

  },
  /* handler:onUploadFail */
  /* handler:onUploadFailMsgAssignment */
  ['onUploadFailMsgAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'failMessageFiles'))

  },
  /* handler:onUploadFailMsgAssignment */
  /* content */
}))

define('./pages/register-offer/components/f9af2127104931dfcdb5acd38b3da3f3/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  async ['onSourceUrlAssignment'](___arguments) {
      return (await (async function(image) {
	return URL.createObjectURL(image)

})(___arguments.context.getComponentDataStoreByUid('65a7b6096b513d68fce9c0e945cd8bd1')))

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'uploadImages')), ___arguments.context.getComponentDataStoreByUid('65a7b6096b513d68fce9c0e945cd8bd1'), '');
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fdd44adf911fd2b51ebeb823239afbe8', (getObjectProperty(___arguments.context.pageData, 'uploadImages')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/register-offer/components/8ca64957b209de3a5e123ae84223e1c5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['Registration_date'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/register-offer/components/eec2c47bcbbfb3b72cdfb10399892c3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['Registration_date'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/register-offer/components/977584e40affa7e2019b4e8161b2cb5d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['RegistrationDate'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/register-offer/components/217352936ea1fd07d4790e35f3b1cb76/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['maker_id'] = ___arguments.value;
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('c2485dfa16ed92dc1f056b2f307c0eca', (getObjectProperty((await Backendless.Data.of('Maker').findById((getObjectProperty(___arguments.context.pageData, 'maker_id')), Backendless.DataQueryBuilder.create().setRelated('Model').setRelationsDepth(1))), 'Model')));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/register-offer/components/c9d8e1d2c322d57f3be14981ab58cd8e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).submit() })('1a5e4fba6eea211787afe84f6e65f34a');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/register-offer/components/1a5e4fba6eea211787afe84f6e65f34a/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var pictures_urls, j, image_no, new_path;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (typeof (getObjectProperty(___arguments.context.pageData, 'uploadImages')) === 'undefined') {
    console.log('no photos');
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71798abf3b4361bdd51cf1b445cfb501', true);
    console.log('scroll');
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('9aa9f645fdeec8bbd83e33de08476279')));
  } else {
    pictures_urls = [];
    image_no = 0;
    new_path = String('/offers_images/') + String((await Backendless.Counters.addAndGet('OfferID', 1)));
    var j_list = (getObjectProperty(___arguments.context.pageData, 'uploadImages'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      image_no = image_no + 1;
      addItemToList(pictures_urls, (await Backendless.Files.saveFile(encodePath(new_path), (String('image_no_') + String(image_no)), j, false)));
    }
    ___arguments.context.dataModel['Photos'] = pictures_urls;
    ___arguments.context.dataModel['Status'] = 'Robocze';
    delete ___arguments.context.dataModel['options_makers'];
    delete ___arguments.context.dataModel['options_years'];
    delete ___arguments.context.dataModel['date_now'];
    if (null != (getObjectProperty(___arguments.context.dataModel, 'Location_name.place_id'))) {
      ___arguments.context.pageData['geolocation'] = (await Backendless.Request.get(`${Backendless.appPath}/services/GooglePlacesAPI/getDetails`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'placeID': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'Location_name.place_id'))) }).send());
      ___arguments.context.dataModel['Location_name'] = (getObjectProperty(___arguments.context.dataModel, 'Location_name.description'));
      ___arguments.context.dataModel['Location_geo'] = (new Backendless.Data.Point().setX((getObjectProperty(___arguments.context.pageData, 'geolocation.lng'))).setY((getObjectProperty(___arguments.context.pageData, 'geolocation.lat'))));
    }
    ___arguments.context.pageData['idOffer'] = (getObjectProperty((await Backendless.Data.of('Offers_v2').save(___arguments.context.dataModel, true)), 'objectId'));
    console.log(getObjectProperty(___arguments.context.pageData, 'idOffer'));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('offer_details', ({ [`idOffer`]: (getObjectProperty(___arguments.context.pageData, 'idOffer')) }));
  }

  },
  /* handler:onSubmit */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    
  },
  /* handler:onMounted */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var years, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['options_makers'] = (await Backendless.Data.of('Maker').find(Backendless.DataQueryBuilder.create().setPageSize(100)));
  years = [];
  var i_end = (new Date((new Date())).getFullYear());
  var i_inc = 1;
  if (1997 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1997; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    addItemToList(years, ({ 'year': i }));
  }
  ___arguments.context.dataModel['options_years'] = years;
  ___arguments.context.dataModel['date_now'] = (new Date());
  ___arguments.context.dataModel['Equipement_audio'] = (await Backendless.Data.of('Options').find(Backendless.DataQueryBuilder.create().setWhereClause('Category =1').setPageSize(100)));
  ___arguments.context.dataModel['Equipement_comfort'] = (await Backendless.Data.of('Options').find(Backendless.DataQueryBuilder.create().setWhereClause('Category =2').setPageSize(100)));
  ___arguments.context.dataModel['Equipement_electrics'] = (await Backendless.Data.of('Options').find(Backendless.DataQueryBuilder.create().setWhereClause('Category =3').setPageSize(100)));
  ___arguments.context.dataModel['Equipement_support'] = (await Backendless.Data.of('Options').find(Backendless.DataQueryBuilder.create().setWhereClause('Category =4').setPageSize(100)));
  ___arguments.context.dataModel['Equipement_tuning'] = (await Backendless.Data.of('Options').find(Backendless.DataQueryBuilder.create().setWhereClause('Category =5').setPageSize(100)));
  ___arguments.context.dataModel['Equipement_safety'] = (await Backendless.Data.of('Options').find(Backendless.DataQueryBuilder.create().setWhereClause('Category =6').setPageSize(100)));
  ___arguments.context.dataModel['Equipement_paperwork'] = (await Backendless.Data.of('Options').find(Backendless.DataQueryBuilder.create().setWhereClause('Category =7').setPageSize(100)));
  ___arguments.context.dataModel['Offer_type'] = 'Osobowe';
  console.log(___arguments.context.dataModel);
  if ((getObjectProperty(___arguments.context.pageData, 'idOffer')) != null) {
    Object.assign(___arguments.context.dataModel, (await Backendless.Data.of('Offers_v2').findById((getObjectProperty(___arguments.context.pageData, 'idOffer')), )));
    console.log(___arguments.context.dataModel);
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/register-offer/components/34722abc888f75f8c6240b7791a4f49f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('1a5e4fba6eea211787afe84f6e65f34a'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/register-offer/components/461536950d95fe631ede374d3ecace4d/bundle.js', [], () => ({
  /* content */
  /* handler:onBlur */
  ['onBlur'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (400 > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1a5e4fba6eea211787afe84f6e65f34a'), 'Price'))) {
    delete ___arguments.context.getComponentDataStoreByUid('1a5e4fba6eea211787afe84f6e65f34a')['Price'];
  }

  },
  /* handler:onBlur */
  /* content */
}))

define('./components/reusable/r_91cd243a8dfe158ba4af2e06bf447b17/logic/root/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e5d45effa759f6baed2fbf9925c85968', (await Backendless.Data.of('Offers_v2').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId != \'',(getObjectProperty(___arguments.context.pageData, 'idOffer')),'\' and Featured = 2 and Active_until >',((new Date()).valueOf())].join(''))).setPageSize(100))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_91cd243a8dfe158ba4af2e06bf447b17/logic/fd61d8fdb56d0813c831343bbde7e437/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await (async function(number) {
	function spaceNumbers(n){
	  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
	}

	return spaceNumbers(number) + " zł";
})((getObjectProperty(___arguments.context.getComponentDataStoreByUid('3838083238b85f256324f389ad96bdb5'), 'Price'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_91cd243a8dfe158ba4af2e06bf447b17/logic/583528d2aa169c9646feb03355226fcb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('3838083238b85f256324f389ad96bdb5'), 'Milage')) + String(' km'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_91cd243a8dfe158ba4af2e06bf447b17/logic/f8816d8e46faec62dc03e2aa5c966208/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('3838083238b85f256324f389ad96bdb5'), 'Production_year'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_91cd243a8dfe158ba4af2e06bf447b17/logic/ba68b217a809362a8915c4f9a78dff61/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var srcImage, images;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (0 != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('3838083238b85f256324f389ad96bdb5'), 'Photos')).length) {
    images = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('3838083238b85f256324f389ad96bdb5'), 'Photos'));
    srcImage = (getObjectProperty(images, '0'));
  } else {
    srcImage = '/web/system/img/elomoto-market-final-grey.svg';
  }

  return srcImage

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_91cd243a8dfe158ba4af2e06bf447b17/logic/3838083238b85f256324f389ad96bdb5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	window.scroll({top: 0, left: 0, behavior: 'smooth'});
  })();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('offer_details', ({ 'idOffer': (getObjectProperty(___arguments.context.dataModel, 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_91cd243a8dfe158ba4af2e06bf447b17/logic/888850d1ca93fed4165cc2ac8ecb2509/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	const specialOffersWrap = document.querySelector(".specialOffers__wrap");

  	if(!specialOffersWrap.dataset.activeEl){
  	    specialOffersWrap.dataset.activeEl = 0
  	}

  	const childWidth = specialOffersWrap.firstChild.clientWidth;
  	let maxSlide = specialOffersWrap.children.length;

  	specialOffersWrap.dataset.activeEl--;

  	if (specialOffersWrap.dataset.activeEl < 0) {
  	  specialOffersWrap.dataset.activeEl = maxSlide - 3;
  	}

  	specialOffersWrap.style.transform = `translateX(${-1 * (childWidth * specialOffersWrap.dataset.activeEl)}px)`;

  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_91cd243a8dfe158ba4af2e06bf447b17/logic/b9aa4346b419e6d3999468a724797152/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	const specialOffersWrap = document.querySelector(".specialOffers__wrap");

  	if(!specialOffersWrap.dataset.activeEl){
  	    specialOffersWrap.dataset.activeEl = 0
  	}

  	const childWidth = specialOffersWrap.firstChild.clientWidth;
  	let maxSlide = specialOffersWrap.children.length;

  	if (specialOffersWrap.dataset.activeEl > maxSlide - 3) {
  	  specialOffersWrap.dataset.activeEl = 0;
  	} else {
  	  specialOffersWrap.dataset.activeEl++;
  	}

  	specialOffersWrap.style.transform = `translateX(${-1 * (childWidth * specialOffersWrap.dataset.activeEl)}px)`;

  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/26ab913d2ce8910f8904b6b35e554e6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/7a57f45298e74845bf677f51b0b5a1ed/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ad1f1730fe77dbed71fd4aeab3bb8f7', (getObjectProperty(___arguments.context.pageData, 'mob_menu_visible')));
  ___arguments.context.pageData['mob_menu_visible'] = (!(getObjectProperty(___arguments.context.pageData, 'mob_menu_visible')));
  console.log((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientWidth', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b38d71a43614d79d7d129581a5776a55'))));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
      return (700 > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientWidth', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b38d71a43614d79d7d129581a5776a55')))))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/ecd99616932e6e983d4940bc050362f1/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('955134f9be4356633affe197fe352703')), 'selected');

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('955134f9be4356633affe197fe352703')).pop();

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('../system/index.html', false, ({ 'page': 'login','redirectToUrl': ((function () { return window.location.href })()) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await Backendless.UserService.getCurrentUser(false)) === null)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/34f2138b6b4f546fa4cbe2db49d6d5c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('../system/index.html', false, ({ 'page': 'registration','sendUserTokenAndId': true }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await Backendless.UserService.getCurrentUser(false)) === null)

  },
  /* handler:onDisplayAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4d995fd865b2d2adf6571dcc20c492aa')), 'selected');

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('4d995fd865b2d2adf6571dcc20c492aa')).pop();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/ad1c8ff3a148aac819934c16df899f29/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await Backendless.UserService.getCurrentUser(false))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3932bb368ae294c23d3315936d6237f4', (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('3932bb368ae294c23d3315936d6237f4')), 'display'))));

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ef18c337fded1eac66edf51864b518c7')), 'selected');

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('ef18c337fded1eac66edf51864b518c7')).pop();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/7d6a4d9a16b3648a80b6940ee8bb4c3c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('my_account', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/6bc3de2be6fc584d3f326592584d2aa2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/83eb279623663636d26b765adab7d0da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      if (null != (await Backendless.UserService.getCurrentUser(false))) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('register-offer', undefined);
  } else {
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('../system/index.html', false, ({ 'page': 'login','redirectToUrl': '../main/index.html?page=register-offer','sendUserTokenAndId': true }));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/726d0fac817c47ac05c33206f37cbe7f/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd3b0a1a63d11ec7bea2ade2202fe0e2')), 'selected');

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('dd3b0a1a63d11ec7bea2ade2202fe0e2')).pop();

  },
  /* handler:onMouseOut */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var list;



  return (0 < (await Backendless.UserService.getUserRoles()).indexOf('SystemAdmin') + 1)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin_page', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_c63d843e70692a99837225ba15c81a08/logic/b38d71a43614d79d7d129581a5776a55/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ad1f1730fe77dbed71fd4aeab3bb8f7', (700 <= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientWidth', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b38d71a43614d79d7d129581a5776a55'))))));

  },
  /* handler:onMounted */
  /* content */
}))

